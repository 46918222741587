<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { SITE_INT_ID_SCS } from '@model/const/sites';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage } from '@utils/html-meta';
import { resolveDataFrom } from '@utils/resolve-data-from';
import { parseDynamicPagePath } from '@utils/routing';
import PagesComponent from './component.vue';

export default Vue.extend({
    name: 'PagesContainer',

    data() {
        return {
            noIndexForced: false,
        };
    },

    async serverPrefetch(): Promise<void> {
        // Init
        const locale = LOCALE_DEFAULT;

        const { previewId } = this.$route.params;
        const { slug, draftKey } = parseDynamicPagePath(this.$route.params.pathMatch);

        const state = this.$store.state.pages;

        // Disable LB cache / SEO indexing for preview
        if (draftKey || previewId) {
            this.$ssrContext.res.headers.push({ key: 'Cache-Control', val: 'no-store' });
            this.noIndexForced = true;
        }

        // Get page
        const pageRequest = new ProductAPIQueryBuilder('page');

        if (previewId) {
            pageRequest.setEntityPath(`/api/utility/cache/${previewId}?time=${Date.now()}`);
        } else {
            pageRequest
                .setEntityPath('/api/core/pages')
                .setLocales([locale])
                .addMatchesAll('slug', '=', slug)
                .addMatchesAll('website_id', '=', SITE_INT_ID_SCS.toString())
                .setPaginate(1, 1)
                .setCustomParam('process-macros', '1');
        }

        if (draftKey) {
            pageRequest.setCustomParam('page', { key: draftKey });
        }

        const requestObj = pageRequest.toObject();
        await this.$store.dispatch('pages/getPage', { request: requestObj });

        if (state.httpStatus !== StatusCodes.OK) {
            throw new HttpRejection('Premature rendering stop', state.httpStatus);
        }

        if (!state.page?.id || !state.page?.body?.length) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        if (draftKey && state.page.is_published) {
            const fixedURL = `/${slug}/`;
            const rejection = new HttpRejection('Premature rendering stop', StatusCodes.MOVED_TEMPORARILY);
            rejection.addHeader('Location', fixedURL);
            throw rejection;
        }

        const enrichedPageBody = await resolveDataFrom(
            state.page.body,
            locale,
            this.$ssrContext.Synced,
        );

        const audiencesQuery = new ProductAPIQueryBuilder('audiences')
            .setEntityPath('/api/core/pages/audiences/')
            .setPaginate(1, 50)
            .toObject();

        const typesQuery = new ProductAPIQueryBuilder('types')
            .setEntityPath('/api/core/pages/types/')
            .setPaginate(1, 50)
            .toObject();

        await Promise.all([
            this.$store.dispatch('pages/getEntity', { request: audiencesQuery }),
            this.$store.dispatch('pages/getEntity', { request: typesQuery }),
        ]);

        await this.$store.dispatch('pages/modifyPageBody', enrichedPageBody);

        // Get linked entities
        const promises: Promise<void>[] = [
            this.$store.dispatch('slices/getSyncedData', { slice: 'global-meta', locale: LOCALE_DEFAULT }),
        ];

        // if (!state.page.settings?.hide_ribbon) {
        //     promises.push(this.$store.dispatch('ribbon/getAll', { locale, slug: state.page.slug }));
        // }

        // TODO: push a banners promise here if needed

        await Promise.all(promises);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const page = this.$store.state.pages.page;

            const globalMeta = this.$store.state.slices.items['global-meta'] || {};
            const imgSrc = page.meta?.image || `@${globalMeta.defaultOgImage}`;
            const ogimage = getOgImage(imgSrc, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = page.meta?.title || 'Acronis SCS – US Public Sector Cyber Protection';
            const description = page.meta?.description || title;
            let isIndexed = page.is_indexed;

            if (this.noIndexForced) {
                isIndexed = false;
            }

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: page.meta?.['og:description'] || description },
                    { tag: 'meta', property: 'og:image', content: ogimage },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    {
                        tag: 'meta',
                        name: 'twitter:description',
                        content: page.meta?.['twitter:description'] || description,
                    },
                    { tag: 'meta', name: 'twitter:image', content: ogimage },
                    isIndexed ? null : { tag: 'meta', name: 'robots', content: 'noindex, nofollow' },
                    { tag: 'link', rel: 'image_src', href: ogimage },
                ],
                htmlAttrs: {
                    dir: 'ltr',
                    lang: 'en',
                },
                ldJsonSchema: page.schema,
            };
        },
    },

    render(h) {
        return h(PagesComponent);
    },
});
</script>
